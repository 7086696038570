<template>
  <message />
</template>

<script>
import Message from './Message.vue'

export default {
  name: 'GoogleRedirect',
  props: ['search'],
  components: {
    Message,
  },
  mounted() {
    const url = window.location.search
    this.$store.dispatch('loginByGoogleCreds', url).then(res => {
      this.message = res.message
      this.$store.dispatch('getUser').then(() => {
        this.$router.push({ name: 'account' })
      })
    })
  },
  data() {
    return {
      message: '',
    }
  },
}
</script>

<style></style>
