<template>
  <div data-vue-name="Message" class="container-fluid authorization">
    <div class="authorization__title authorization__title_center">Done</div>
    <div class="authorization__message">
      {{ message }}
    </div>

    <button v-if="callback" class="btn-style1">Done</button>
  </div>
</template>

<script>
export default {
  name: 'Message',
  props: {
    message: {
      type: String,
      default: '',
      required: false,
    },
    color: String,
    callback: Function,
  },
  computed: {
    colorStyle() {
      return this.color ?? 'rgba(255, 255, 255, 0.85)'
    },
  },
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="stylus">
.danger {
display: block
  color: red !important
         margin: -15px 0 5px 15px
}
.authorization {
  height 100%
  width 100%
  background url("../assets/img/bg-page-auth-1.png") no-repeat
  background-size cover
  background-position: center;
  display flex
  flex-direction column
}

.authorization__title {
  font-family: 'Poppins', sans-serif;
  font-size 36px
  font-weight: 600;
  line-height: 122%;
  color: #EEEEEE;
  margin-top 96px
  margin-bottom 16px

  &_center {
    margin-top 200px
    text-align center
  }
}

.authorization__message {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.85);
  text-align: center;
}

.authorization__form {
  width 100%
  margin-bottom 72px
}

.authorization__form > div {
  position relative
}

.authorization__form i {
  position absolute
  z-index 10
  cursor: pointer;
  width 22px
  height 13px
  right 18px
  top 15px
}

.authorization__form input {
  width 100%
  background: rgba(255, 255, 255, 0.15);
  border-radius: 12px;
  margin-bottom 16px
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #D8D8D8 !important
  padding 13px 35px 13px 16px
}

.authorization__form input::placeholder {
  color: #7B8B97;
}

.authorization__form .btn-style1 {
  height 56px
  margin-top 8px
  margin-bottom 24px
}

.authorization__btn-style-link {
  color: #6293FF;
  margin-top 4px
  text-decoration none
}

.authorization__other-account {
  margin-bottom 75px
}

.authorization__other-account > div {
  margin-bottom 13px
}

.authorization__other-account button {
  background: #D8D8D8;
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content center
  margin-bottom 16px
  width 100%
  height 40px
  font-family: 'Roboto', sans-serif

  svg {
    margin-right 24px
  }
}
</style>
